import React from 'react';
import { Link, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import SEO from '../components/SEO';
import Dots from '../components/Dots';
import meet_greet from '../images/meet-and-greet.png';
import Img from 'gatsby-image';
import Layout from '../layouts/index';

class aboutUs extends React.Component {
    render() {
      return (
        <Layout bodyClass="page-aboutUs">
          <div className="intro pt-6 pt-sm-8">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Who We Are</h1>
                </div>
              </div>
              <hr className="section-divider mt-sm-7 mb-4 mb-sm-9" />
            </div>        
          </div>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-6 pb-3 intro-des">
                <p className="text-muted text-center">Lilardia Capital is both a market place and an enterprise application software provider to Financial institutions, Corporates, Small and Medium Size (SME) and Micro Businesses.</p>
              </div>
            </div>
          </div>
          <div className="container conntainer-goals">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <span><p className="text-muted ">Our goals are:</p></span>
                <Fade delay={200}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Improve & Grow Businesses of All Sizes</h4>
                      <p>To improve and grow businesses of all sizes around the world. We provide a treasury management system which enables businesses to connect with their suppliers, financiers, payment and accounting systems.</p>
                    </div>
                  </div>
                </Fade>
                <Fade delay={400}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Connect Transparently & Digitally</h4>
                      <p>Our applications and services enables our customers to operate profitably, adapt continuously enabling financiers, purchasers and suppliers to connect transparently and digitally.</p>
                    </div>
                  </div>
                </Fade>
                <Fade delay={600}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Assist Global & Local Financiers</h4>
                      <p>Our mission is to assist ethically responsible global and local financiers and to help them transact with purchasers and suppliers around the world..</p>
                    </div>
                  </div>
                </Fade>
                <Fade delay={800}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Guide Purchasers, Suppliers & Financiers</h4>
                      <p>Lilardia Capital will guide purchasers, suppliers and financiers through the easy to use invoice management and financing process . Our marketplace ensures the suppliers are able to choose the most favourable possible rates and options for early finance for their invoice.</p>
                    </div>
                  </div>
                </Fade>
                <Fade delay={1000}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Provide Leading Solutions to a Wide Variety of Clients</h4>
                      <p>Based in Australia, Singapore and Sri Lanka the team is able to provide leading solutions to a wide variety of clients.</p>
                    </div>
                  </div>
                </Fade> 
                
                <p className="text-primary my-5 text-center dsplay-hid">In the coming weeks we will provide more details about our exciting global partners, advisory board members and management.</p>
               
              </div>
            </div>
          </div>          
          <div className="container p-3">
            <div className="container-primary dsplay-hid">
              <div className="row">
                <div className="col-md-8">
                  <h6>Meet & Greet Us</h6> 
                  <h5>We will have a soft launch at the <strong>Asian</strong> 
                  <strong> Banker Conference </strong>in <strong>Bangkok</strong> in late<strong> May</strong>.
                  <br></br>
                  <br></br>
                  Bring all your question. 
                  We are excited to meet you there!</h5>
                </div>
                <div className="col-md-4">
                
                <div className="card card-service">
                  <img className="meet_greet" alt="meet_greet" src={meet_greet} />
                </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }


  }
  
export default aboutUs;
